import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import styles from "components/Lib/Dropdown/DropdownStyles.module.scss";
import "./Events.scss";
import { useTranslation } from "react-i18next";
import useEstimateEarning from "components/hooks/useEstimatesEarning";
import { EVENT_TYPES } from "../Constants/ChartConstants";
import ErrorPopover from "../Popover/ErrorPopover";

const Events = ({ venueXid, chart, unregisterChartEvents, registerChartEvents }) => {
	const [event, setEvent] = useState(false);
	const [arrow, setArrow] = useState("fa-angle-down");
	const [show, setShow] = useState(false);
	const [showPopover, setShowPopover] = useState(false);
	const { t } = useTranslation();
	const { chartData, error } = useEstimateEarning(venueXid, "QTR");
	const [target, setTarget] = useState(null);

	function handleEvents(e) {
		if (chart) {
			if (event) {
				registerChartEvents();
				chart.panels[0].removeEvent(EVENT_TYPES.CUSTOM);
				setEvent(false);
				chart.loadData();
			} else {
				if (error || (chartData.actuals && chartData.actuals.length === 0)) {
					e && e.preventDefault();
					setShowPopover(true);
					setTarget(hasSomeParentTheClass(e.target));
					console.log(error);
				} else {
					registerChartEvents();
					chart.panels[0].addEvent(EVENT_TYPES.CUSTOM, {
						name: EVENT_TYPES.EARNINGS_CUSTOM,
						dataset: chartData.actuals,
					});
					setEvent(true);
					chart.loadData();
				}
			}
		}
		return () => unregisterChartEvents();
	}
	function hasSomeParentTheClass(element) {
		if (element.className.split(" ").indexOf("events-button") >= 0) {
			return element;
		} else {
			return hasSomeParentTheClass(element.parentNode);
		}
	}
	const dropdownToggle = (isOpen) => {
		if (!isOpen) setShow(true);
		else setShow(false);
		if (arrow === "fa-angle-up") {
			setArrow("fa-angle-down");
			setShow(false);
		} else setArrow("fa-angle-up");
	};

	return (
		<>
			<div className="events-button" data-testid="eventsDropdown">
				<Dropdown show={show} onToggle={() => dropdownToggle(show)}>
					<Dropdown.Toggle
						className={styles.dropdownToggle}
						data-testid="dropdownButton"
						id="dropdownButton"
					>
						{t("Events")} <i className={`fa ${arrow} ${styles.DropDownIcon}`}></i>{" "}
					</Dropdown.Toggle>
					<Dropdown.Menu className={`${styles.dropdownMenu} eventMenu`}>
						<Dropdown.Item
							data-testid="itemEarnings"
							className={event ? styles.selected : styles.dropdownItem}
							onClick={(e) => handleEvents(e)}
						>
							<div className="dropdown_item">
								<span className="dropdown_text" id="event-item">
									{t("Earnings (Last 2Y)")}
								</span>
							</div>
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
				<ErrorPopover
					showPopover={showPopover}
					target={target}
					msg={"There is currently no earnings data available."}
					setShowPopover={setShowPopover}
				></ErrorPopover>
			</div>
		</>
	);
};

export default Events;
