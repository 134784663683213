import { Card, Table, Row, Col } from "react-bootstrap";
import Error from "components/Error/Error";
import useInstitutionsAggregates from "components/hooks/BigDough/useInstitutionsAggregates";
import { useTranslation } from "react-i18next";
import formatter from "utils/formatUtils";
import AggregateTypes from "Constants/AggregateTypes";
import "./InstitutionStyle.scss";
import Skeleton from "react-loading-skeleton";
import React, { useState } from "react";
import SubscriptionModal from "components/Common/SubscriptionModal";
import SubscriptionLink from "components/Common/SubscriptionLink";

const InstitutionStyle = ({ instrumentXid }) => {
	const { institutionsAggregates, error, loading, fetchedAt, sort } =
		useInstitutionsAggregates(instrumentXid, AggregateTypes.Style);
	const { t } = useTranslation();
	const cdnDomain = window.MD && window.MD.CDN_DOMAIN;
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	function getStylesSumExceptTop5(styles, isNotStyledInTop5) {
		let othersSum = 0;
		let sumPercentHeldExceptNullName = styles
			?.filter((f) => f.name !== undefined && f.name !== "")
			?.slice(6, styles.length);
		if (sumPercentHeldExceptNullName && sumPercentHeldExceptNullName.length > 0) {
			sumPercentHeldExceptNullName = sumPercentHeldExceptNullName?.reduce((a, b) => ({
				percentHeldInstitutional: a.percentHeldInstitutional + b.percentHeldInstitutional,
			}));
		}
		if (!isNotStyledInTop5) {
			let blankPercentHeldInstitutional = styles.find(
				(f) => f.name === undefined || f.name === ""
			);
			if (blankPercentHeldInstitutional) {
				othersSum =
					sumPercentHeldExceptNullName?.percentHeldInstitutional +
					blankPercentHeldInstitutional?.percentHeldInstitutional;
			} else {
				othersSum = sumPercentHeldExceptNullName?.percentHeldInstitutional;
			}
		} else {
			othersSum = sumPercentHeldExceptNullName?.percentHeldInstitutional;
		}
		return othersSum;
	}

	const renderErrorMessage = (err) => {
		let errorMessage = "There was a problem loading institution style data.";
		if (err && err.response && err.response.status === 404)
			errorMessage = "There is currently no institution style data available.";

		return <Error errorMessge={t(errorMessage)}></Error>;
	};

	const renderInstitutionStyles = (styles) => {
		if (!styles || styles.length === 0) return;
		let totalRecords = styles.length;
		let isNotStyledInTop5 = false;
		let stylesList;
		//Check if Null is top one
		if (totalRecords > 6) {
			let notStyled = styles
				.sort(sort)
				.slice(0, 6)
				.find((f) => f.name === undefined || f.name === "");
			if (notStyled) {
				isNotStyledInTop5 = true;
			}

			let othersSum = getStylesSumExceptTop5(styles, isNotStyledInTop5);

			let topsStyles = styles.filter((f) => f.name !== undefined && f.name !== "");

			if (isNotStyledInTop5) topsStyles = styles;
			stylesList = [
				...topsStyles.sort(sort).slice(0, 6),
				{ name: "Others", percentHeldInstitutional: othersSum },
			];
		} else {
			stylesList = [...styles.sort(sort).slice(0, totalRecords)];
		}

		return stylesList.sort(sort).map((item, index) => {
			let progressBarValue = item.percentHeldInstitutional * 100;
			return (
				<tr key={index}>
					<td className="instiutionName">
						{item.name || (!item.name && "No style attributed")}
					</td>
					<td className="instiutionPercentHeld font-weight-bold">
						{formatter.percent(progressBarValue, 2)}
					</td>
					<td className="instiutionPerBar font-weight-bold">
						<div className="progress">
							<div
								className="progress-bar"
								style={{ width: `${progressBarValue}%` }}
								aria-valuenow={progressBarValue}
								aria-valuemin="0"
								aria-valuemax="1"
							></div>
						</div>
					</td>
				</tr>
			);
		});
	};

	const showSkeleton = () => {
		return [1, 2, 3, 4, 5].map(() => {
			return (
				<tr>
					<td className="instiutionName">
						<Skeleton className="name-skeleton" />
					</td>
					<td className="instiutionPercentHeld">
						<Skeleton className="percent-skeleton" />
					</td>
					<td className="instiutionPerBar">
						<div className="progress">
							<Skeleton className="bar-skeleton" />
						</div>
					</td>
				</tr>
			);
		});
	};

	return (
		<Card>
			<Card.Header className="bg-white hk-sectionhdr">
				<h2>{t("Institution Style")}</h2>
				<div className="hk-sectionhdr-line"></div>
			</Card.Header>
			<Card.Body className="card-body-map">
				{error && renderErrorMessage(error)}
				<Table
					striped
					responsive="sm"
					borderless
					id="institutionsTable"
					data-testid="institutions-table-testid"
					className="institutions-tbl"
				>
					<tbody>
						{loading && showSkeleton()}
						{institutionsAggregates &&
							!loading &&
							renderInstitutionStyles(institutionsAggregates)}
					</tbody>
				</Table>
				<Row className="mt-20 mt-xs-10 institutionStyleFooter">
					<Col xs={12} md={12} lg={12} xl={6}>
						<span className="hkt-time">
							{loading && <Skeleton className="style-date-skeleton" />}
							{!loading && <>{t("Data source: S&P Global")}</>}
						</span>
					</Col>
					<Col xs={12} md={12} lg={12} xl={6}>
						<div className="txt-subscribe text-end txt-subscribe-tab">
							{
								<SubscriptionLink
									cdnDomain={cdnDomain}
									handleShow={handleShow}
									className="InstitutionStyle"
								/>
							}
							{
								<SubscriptionModal
									show={show}
									handleShow={handleShow}
									handleClose={handleClose}
								/>
							}
						</div>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
};

export default InstitutionStyle;
