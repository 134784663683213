import { useState, useEffect, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown, Row, Col } from "react-bootstrap";
import styles from "components/Lib/Dropdown/DropdownStyles.module.scss";
import SearchHelper from "components/Search/Search-utils";
import "./Benchmark.scss";
import searchIcon from "assets/img/search_icon_14x14.svg";
import clearSearchIcon from "assets/img/clear_search.svg";
import ErrorPopover from "../Popover/ErrorPopover";
import useBenchmarks from "components/hooks/useBenchmarks";
import Loader from "components/Lib/Loader/Loader";
import Error from "components/Error/Error";
import { PeersContext } from "components/contexts/usePeersContext";
const Benchmark = ({
	chart,
	removeLegend,
	setRemoveLegend,
	setLegends,
	setEquities,
	registerChartEvents,
	unregisterChartEvents,
	selectedBenchmarks,
	setDollarFormat,
	checkStylesAdd,
	checkStylesRemove,
	peers,
	peersError,
	peersLoading,
}) => {
	const { benchmarks, loading: benchmarkLoading } = useBenchmarks();
	const [filteredEquities, setFilteredEquities] = useState([]);
	const [equityEntered, setEquityEntered] = useState("");
	const [selectedComparisons, setSelectedComparisons] = useState(selectedBenchmarks);
	const [loading, setLoading] = useState(false);
	const dropDownMenuRef = useRef(null);
	const dropDownRef = useRef(null);
	const dropDownButtonRef = useRef(null);
	const { t } = useTranslation();
	const [arrow, setArrow] = useState("fa-angle-down");
	const [show, setShow] = useState(false);
	const [showPopover, setShowPopover] = useState(false);
	const [target, setTarget] = useState(null);
	const searchInputRef = useRef(null);
	let cdnDomain = window.MD && window.MD.CDN_DOMAIN;
	var regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
	useEffect(() => {
		if (chart && selectedComparisons.length > 0) {
			registerChartEvents();
			chart.setSymbolCompare(selectedComparisons);
			chart.loadData();
			return () => unregisterChartEvents();
		}
	}, [selectedComparisons]);

	useEffect(() => {
		if (removeLegend) {
			let chartIndicators = chart.panels[0].indicators;
			let indicator = chartIndicators.find((i) => i.params.uid === removeLegend);

			if (indicator && indicator.params.id === "price") {
				chartIndicators.forEach((element) => {
					if (element.params.id === "price" && element.params.uid === removeLegend) {
						setSelectedComparisons(
							selectedComparisons.filter(
								(elem) => elem.toString() !== element.params.symbol
							)
						);
					}
				});
				checkStylesRemove(indicator);
				try {
					chart.panels[0].removeIndicator(indicator);
				} catch {
					console.log("No ChartData Available");
				}
				setLegends(chart.panels[0].indicators);
				if (
					chartIndicators.filter((ele) => ele.params.id === "price").length < 2 &&
					chartIndicators[0].params.markerType !== "posneg"
				) {
					chart.params.dataNormalized = false;
					setDollarFormat(true);
				}

				chart.loadData();
				setRemoveLegend(indicator.params.uid);
			}
		}
	}, [removeLegend]);

	useEffect(() => {
		// To avoid race condition used activeCall flag to handle effect
		let activeCall = true;
		async function getXrefData(params) {
			try {
				setLoading(true);
				const options = !regex.test(equityEntered)
					? await SearchHelper.getXrefData(equityEntered, 6)
					: "";
				if (activeCall) {
					setLoading(false);
					if (options && options.length > 0) {
						setFilteredEquities(
							options.filter((element) => {
								return (
									element.classification.name === "Equity" &&
									element.xids.venue.toString() !== chart.params.symbol.toString()
								);
							})
						);
					} else {
						setFilteredEquities([]);
					}
				}
			} catch (error) {
				console.log(error);
				setLoading(false);
			}
		}
		getXrefData();
		return () => {
			activeCall = false;
		};
	}, [equityEntered]);

	const hideMenu = () => {
		if (dropDownMenuRef.current) {
			dropDownMenuRef.current.classList.value =
				dropDownMenuRef.current.classList.value.replace("show", "");
		}
		if (dropDownRef.current) {
			dropDownRef.current.classList.value = dropDownRef.current.classList.value.replace(
				"show",
				""
			);
		}
		if (dropDownButtonRef.current) {
			dropDownButtonRef.current.ariaExpanded = false;
		}
	};

	// To find the top parent element to set target for error message
	function hasSomeParentTheClass(element) {
		if (element.className.split(" ").indexOf("benchmarks-button") >= 0) {
			return element;
		} else {
			return hasSomeParentTheClass(element.parentNode);
		}
	}

	const handleSelect = async (e, id, symbol) => {
		hideMenu();
		if (!selectedComparisons.find((f) => f === id)) {
			if (chart && chart.panels[0].indicators.length < 11) {
				//less than 11 as Price and Volume are always added
				let addedBenchmarks = [...selectedComparisons, id];
				// TODO handle removal of comparisons using chart object
				setSelectedComparisons(addedBenchmarks);
				setEquities({ id, symbol });
				checkStylesAdd();
				setDollarFormat(false);
			} else {
				setShowPopover(true);
				let parent = hasSomeParentTheClass(e.target);
				setTarget(parent);
			}
		}
		dropdownToggle(show);
	};

	const handleFilter = async (event) => {
		const searchSymbol = event.target.value;
		setEquityEntered(searchSymbol);
	};

	const clearSearchInput = () => {
		setFilteredEquities([]);
		setEquityEntered("");
	};

	const dropdownToggle = (isOpen) => {
		if (!isOpen) setShow(true);
		else setShow(false);
		if (arrow === "fa-angle-up") {
			setArrow("fa-angle-down");
			setShow(false);
		} else setArrow("fa-angle-up");
	};

	const getHighlightedText = (text) => {
		let searchKey = equityEntered?.replace(/[^\w\s]/gi, "");
		const parts = text?.split(new RegExp(`(${searchKey})`, "gi"));
		return (
			<span>
				{parts.map((part, index) =>
					part.toLowerCase() === searchKey.toLowerCase() ? (
						<b key={index}>{part}</b>
					) : (
						part
					)
				)}
			</span>
		);
	};
	const renderErrorMessage = (peersError) => {
		let errorMessage = "There was a problem loading peer comparison.";
		return (
			<Error errorMessge={t(errorMessage)} bgClass={"errorInfo-forDropdown"}></Error>
		);
	};
	const renderSearchDropdown = () => {
		if (equityEntered != "" && filteredEquities.length > 0) {
			return (
				<ul className="equityResults">
					{filteredEquities.map((value) => {
						return (
							<li
								data-testid="search_Option"
								key={value.xids.venue}
								className="benchmarkSearchResults"
								onClick={(e) => {
									handleSelect(e, value.xids.venue, value.symbol);
									clearSearchInput();
								}}
								onKeyUp={(e) => {
									if (e.key === "Enter") {
										handleSelect(e, value.xids.venue, value.symbol);
										clearSearchInput();
									}
								}}
								tabIndex={"0"}
							>
								<div className="symbol">{getHighlightedText(value.symbol)}</div>
								<div className="benchmark-peer-name" key={value.id}>
									{getHighlightedText(value.name)}
								</div>
							</li>
						);
					})}
				</ul>
			);
		} else if (!loading && equityEntered != "" && filteredEquities.length === 0) {
			return (
				<ul className="equityResults">
					<li data-testid="search_Option" className="benchmarkSearchResults">
						<span className="noResultsFound">{t("No results found")}</span>
					</li>
				</ul>
			);
		}
	};

	return (
		<>
			<div className="benchmarks-button">
				<Dropdown
					show={show}
					onToggle={() => {
						dropdownToggle(show);
						clearSearchInput();
					}}
					ref={dropDownRef}
				>
					<Dropdown.Toggle
						className={styles.dropdownToggle}
						data-testid="dropdownButton"
						id="dropdownButton"
						ref={dropDownButtonRef}
					>
						{t("Benchmark")}
						<i className={`fa ${arrow} fa-md ${styles.DropDownIcon}`}></i>
					</Dropdown.Toggle>
					<Dropdown.Menu
						ref={dropDownMenuRef}
						className={
							!peersError
								? `${styles.dropdownMenu} benchmarkDropdown`
								: `${styles.dropdownMenu} benchmarkDropdownForNoPeers`
						}
					>
						<div className="benchmarksSearch">
							<div className="searchMenu">
								<img
									className="searchIcon"
									aria-hidden="true"
									src={`${cdnDomain}${searchIcon}`}
									alt="search"
									onClick={() => {
										searchInputRef.current.focus();
									}}
								></img>
								<input
									ref={searchInputRef}
									type="text"
									placeholder={t("Stock code or Company")}
									value={equityEntered}
									onChange={handleFilter}
								/>
								{equityEntered != "" && (
									<img
										className="clearIcon"
										aria-hidden="true"
										src={`${cdnDomain}${clearSearchIcon}`}
										alt="clear search"
										onClick={clearSearchInput}
									></img>
								)}

								{renderSearchDropdown()}
							</div>
							<Dropdown.ItemText className={`${styles.dropdownItemText} border-top`}>
								{t("Indexes")}
							</Dropdown.ItemText>
							<div className="benchmarks">
								{benchmarkLoading && (
									<div className="centered-loader">
										<Loader />
									</div>
								)}
								{benchmarks.map((value) => {
									return (
										<Dropdown.Item
											data-testid="dropdownIndexItems"
											key={value.id}
											className={`${styles.dropdownItem} benchmark-item`}
											onClick={(e) => handleSelect(e, value.id, value.symbol)}
											onKeyUp={(e) => {
												if (e.key === "Enter") {
													handleSelect(e, value.id, value.symbol);
													clearSearchInput();
												}
											}}
										>
											<Col id="benchmark-item"> {t(value.name)}</Col>
										</Dropdown.Item>
									);
								})}
							</div>
							<div>
								<Row className="border-top">
									<Col className={styles.dropdownItemText}>{t("Peers")}</Col>
								</Row>
								{peersError && renderErrorMessage(peersError)}
								{peersLoading && (
									<div className="centered-loader">
										<Loader />
									</div>
								)}
								{!peersLoading &&
									!peersError &&
									peers &&
									peers
										.filter((item) => item.name !== undefined)
										.map((value) => {
											return (
												value &&
												value.isVisible && (
													<Dropdown.Item
														data-testid="dropdownPeerItems"
														key={value.id}
														className={`${styles.dropdownItem} benchmark-item`}
														onClick={(e) => handleSelect(e, value.venueXid, value.symbol)}
														onKeyUp={(e) => {
															if (e.key === "Enter") {
																handleSelect(e, value.venueXid, value.symbol);
																clearSearchInput();
															}
														}}
													>
														<div className="peer-symbol-item" id="benchmark-item">
															{value.symbol}
														</div>
														<div className="peer-name-item">{value.name}</div>
													</Dropdown.Item>
												)
											);
										})}
							</div>
						</div>
					</Dropdown.Menu>
				</Dropdown>
				<ErrorPopover
					showPopover={showPopover}
					target={target}
					setShowPopover={setShowPopover}
				></ErrorPopover>
			</div>
		</>
	);
};

export default Benchmark;
