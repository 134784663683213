import { useState, useContext, useEffect } from "react";
import { Card, Table, OverlayTrigger, Popover } from "react-bootstrap";
import usePeersShareholder from "components/hooks/BigDough/usePeersShareholder";
import useInstrumentList from "components/hooks/useInstrumentList";
import InfoIconToolTip from "../../assets/img/InfoIconGray.svg";
import InfoIcon from "../../assets/img/InfoIconBlue.svg";
import { useTranslation } from "react-i18next";
import Error from "components/Error/Error";
import "./PeerShareholders.scss";
import { SymbolContext } from "components/hooks/useSymbol";
import { PeersContext } from "components/contexts/usePeersContext";
import Skeleton from "react-loading-skeleton";
import SubscriptionModal from "components/Common/SubscriptionModal";
import SubscriptionLink from "components/Common/SubscriptionLink";

const PeerShareholders = ({ instrumentXid, peerXIDs }) => {
	const { symbol } = useContext(SymbolContext);
	const numberOfResults = 5;
	const isSmallDevice = window.innerWidth <= 1024 ? true : false;
	const { t } = useTranslation();
	const [peerInstrumentXidList, setPeerInstrumentXidList] = useState([]);
	const [institutionList, setInstitutionList] = useState([]);
	const { peersDataXREF } = useContext(PeersContext);
	const cdnDomain = window.MD && window.MD.CDN_DOMAIN;
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	//get venueIds
	const { peersLoading } = useContext(PeersContext);
	// const { peersLoading } = usePeers(venueXid, setPeerXIDs);

	useEffect(() => {
		if (!peersDataXREF || (peersDataXREF && peersDataXREF.length === 0)) {
			setInstitutionList(null);
		}
	}, [peersDataXREF, institutionList]);

	//get InstrumentXids
	const { instrumentListLoading } = useInstrumentList(setPeerInstrumentXidList);

	//get Peershareholder
	const { error, loading, fetchedAt } = usePeersShareholder(
		instrumentXid,
		peerInstrumentXidList,
		numberOfResults,
		setInstitutionList
	);

	const renderOverlay = (
		<Popover id="hk-popover-container" className="hk-popover-container">
			<Popover.Body className="hk-peer-popover">
				<div className="error-popover" data-testid="error-popover">
					<div>
						<div className="">
							<img src={InfoIconToolTip} alt="Alert" />
						</div>
					</div>
					<div className="error-txt">
						{t("PEER_SHAREHOLDER_POPOVER_TEXT", { symbol: symbol })}
					</div>
				</div>
			</Popover.Body>
		</Popover>
	);

	const renderErrorMessage = (error) => {
		let errorMessage = "There was a problem loading peer shareholders data.";
		if ((error && error.response && error.response.status === 404) || !institutionList)
			errorMessage = "There is currently no peer shareholders data available.";

		return (
			<Error errorMessge={t(errorMessage)} bgClass={"errorInfo-nobg-desktop"}></Error>
		);
	};

	return (
		<Card className={isSmallDevice ? "bg-white hk-sectionhdr" : "bg-gray hk-sectionhdr"}>
			<Card.Header
				className={isSmallDevice ? "bg-white hk-sectionhdr" : "bg-gray hk-sectionhdr"}
			>
				<h2>
					{t("Peer Shareholders")}
					<OverlayTrigger placement="top" overlay={renderOverlay}>
						<img
							src={`${cdnDomain}${InfoIcon}`}
							alt="Peer Shareholders Info"
							data-testid="peer-shareholders-info"
							className="infoIconBlue"
						/>
					</OverlayTrigger>
				</h2>
				<div className="hk-sectionhdr-line"></div>
			</Card.Header>
			{loading || peersLoading || instrumentListLoading ? (
				<Card.Body className="bg-white card-body">
					<Table
						striped
						className="hk-table-striped"
						data-testid="peer-shareholders-table"
					>
						<thead>
							<tr>
								<th>{t("BD Institution Name")}</th>
								<th>{t("Primary Institution Type")}</th>
								<th className="text-start">{t("Side")}</th>
							</tr>
						</thead>
						<tbody data-testid="body-container">
							{[1, 2, 3, 4, 5].map(() => {
								return (
									<tr>
										<td>
											<Skeleton />
										</td>
										<td>
											<Skeleton />
										</td>
										<td className="text-start">
											<Skeleton />
										</td>
									</tr>
								);
							})}
						</tbody>
					</Table>
				</Card.Body>
			) : (
				<Card.Body className="bg-white card-body">
					{institutionList && institutionList.length > 0 && (
						<Table
							striped
							className="hk-table-striped"
							data-testid="peer-shareholders-table"
						>
							<thead>
								<tr>
									<th>{t("BD Institution Name")}</th>
									<th>{t("Primary Institution Type")}</th>
									<th className="text-start">{t("Side")}</th>
								</tr>
							</thead>
							<tbody data-testid="body-container">
								{institutionList &&
									Array.isArray(institutionList) &&
									institutionList.length > 0 &&
									institutionList.map((institution, key) => {
										return (
											<tr key={key} data-testid={`body-testid-${key}`}>
												<td>{institution.orgName}</td>
												<td>{institution.organizationType}</td>
												<td className="text-start">{institution.categoryType}</td>
											</tr>
										);
									})}
							</tbody>
						</Table>
					)}
					{((!loading && !peersLoading && !instrumentListLoading && error) ||
						!institutionList) &&
						renderErrorMessage(error)}
				</Card.Body>
			)}
			<Card.Footer className="bg-white card-footer">
				{loading && <Skeleton className="style-date-skeleton" />}
				{!loading && institutionList && (
					<span className="hkt-time">{t("Data source: S&P Global")}</span>
				)}
			</Card.Footer>
			<div className="hk-link-external txt-subscribe">
				{
					<SubscriptionLink
						cdnDomain={cdnDomain}
						handleShow={handleShow}
						className="PeerShareholders"
					/>
				}
				{
					<SubscriptionModal
						show={show}
						handleShow={handleShow}
						handleClose={handleClose}
					/>
				}
			</div>
		</Card>
	);
};

export default PeerShareholders;
