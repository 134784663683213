import { useState, useEffect, useContext } from "react";
import { Card, Table, OverlayTrigger, Popover } from "react-bootstrap";
import Utils from "../../utils/utils";
import useInsidersList from "components/hooks/BigDough/useInsidersList";
import formatter from "utils/formatUtils";
import "./CorporateOwnership.scss";
import InfoIconToolTip from "../../assets/img/InfoIconGray.svg";
import InfoIcon from "../../assets/img/InfoIconBlue.svg";
import { useTranslation } from "react-i18next";
import Error from "components/Error/Error";
import Skeleton from "react-loading-skeleton";
import { SymbolContext } from "components/hooks/useSymbol";
import SubscriptionModal from "components/Common/SubscriptionModal";
import SubscriptionLink from "components/Common/SubscriptionLink";

const CorporateOwnership = ({ instrumentXid }) => {
	const { symbol } = useContext(SymbolContext);
	const { t } = useTranslation();
	const [fetchedAt, setFetchedAt] = useState(true);
	const { insidersList, error, loading } = useInsidersList(instrumentXid);
	const width = window.innerWidth <= 1024 ? true : false;
	const widthMobile = window.innerWidth < 768 ? true : false;
	const cdnDomain = window.MD && window.MD.CDN_DOMAIN;
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	useEffect(() => {
		setFetchedAt(Utils.getHKTTime());
	}, []);

	const renderOverlay = (
		<Popover className="hk-corp-popover-container">
			<Popover.Body className="hk-corp-popover">
				<div className="error-popover" data-testid="error-corp-popover">
					<div>
						<div className="">
							<img src={InfoIconToolTip} alt="Alert" />
						</div>
					</div>
					<div className="error-txt">
						{t("CORPORATE_OWNERSHIP_POPOVER_TEXT", { symbol: symbol })}
					</div>
				</div>
			</Popover.Body>
		</Popover>
	);

	const renderErrorMessage = (error) => {
		let errorMessage = "There was a problem loading corporate ownership data.";
		if (error && error.response && error.response.status === 404)
			errorMessage = "There is currently no corporate ownership data available.";

		return (
			<Error errorMessge={t(errorMessage)} bgClass={"errorInfo-nobg-desktop"}></Error>
		);
	};

	return (
		<Card>
			<Card.Header className={width ? "bg-white hk-sectionhdr" : "bg-gray hk-sectionhdr"}>
				<h2>
					{t("Corporate Ownership")}
					<OverlayTrigger placement="top" overlay={renderOverlay}>
						<img
							src={`${cdnDomain}${InfoIcon}`}
							alt="Corporate Ownership Info"
							data-testid="corporate-ownership-info"
							className="corpInfoIconBlue"
						/>
					</OverlayTrigger>
				</h2>
				<div className="hk-sectionhdr-line"></div>
			</Card.Header>
			<Card.Body className="ownership-cardBody">
				{loading && (
					<Table striped className="corporateOwnership">
						<thead>
							<tr>
								<th scope="col">{t("Name")}</th>
								<th scope="col">{t("Share Quantity")}</th>
								<th scope="col">{t("Percent Held")}</th>
							</tr>
						</thead>
						<tbody>
							{[1, 2, 3, 4, 5].map(() => {
								return (
									<>
										<tr>
											<td aria-hidden="true" className="first-col-wdth">
												<Skeleton />
											</td>
											<td className="second-col-wdth">
												<Skeleton />
											</td>
											<td className="third-col-wdth">
												<Skeleton />
											</td>
										</tr>
									</>
								);
							})}
						</tbody>
					</Table>
				)}
				{insidersList && !loading && insidersList.length > 0 && (
					<Table striped className="corporateOwnership">
						<thead>
							<tr>
								<th scope="col">{t("Name")}</th>
								<th scope="col">{t("Share Quantity")}</th>
								<th scope="col">{t("Percent Held")}</th>
							</tr>
						</thead>
						<tbody>
							{insidersList.slice(0, 5).map((item, key) => {
								return (
									<tr key={key}>
										{
											<>
												<td aria-hidden="true" className="first-col-wdth">
													{((item.firstName || item.lastName) && (
														<>
															{item.firstName} {item.lastName}
														</>
													)) ||
														(!item.firstName && !item.lastName && "-")}
												</td>
												<td className="second-col-wdth">
													{((item.shareQuantity || item.shareQuantity === 0) &&
														item.shareQuantity.toLocaleString("en-US")) ||
														(item.shareQuantity === undefined && "-")}
												</td>
												<td className="third-col-wdth">
													{(item.percentHeld || item.percentHeld === 0) &&
														(item.percentHeld === 0
															? "0.00%"
															: formatter.percent(item.percentHeld, 2))}
													{item.percentHeld === undefined && "-"}
												</td>
											</>
										}
									</tr>
								);
							})}
						</tbody>
					</Table>
				)}
				{(!insidersList || insidersList === null || error) &&
					!loading &&
					renderErrorMessage(error)}
			</Card.Body>
			<Card.Footer className="ownership-cardFooter">
				{loading && <Skeleton className="style-date-skeleton" />}
				{!loading && <span className="hkt-time">{t("Data source: S&P Global")}</span>}
				{widthMobile && (
					<div className="txt-subscribe txt-subscribe-ownership">
						{
							<SubscriptionLink
								cdnDomain={cdnDomain}
								handleShow={handleShow}
								className="CorporateOwnership"
							/>
						}
						{
							<SubscriptionModal
								show={show}
								handleShow={handleShow}
								handleClose={handleClose}
							/>
						}
					</div>
				)}
			</Card.Footer>
		</Card>
	);
};

export default CorporateOwnership;
