import React, { useEffect, useState, useRef } from "react";
import styles from "components/Lib/Dropdown/DropdownStyles.module.scss";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
	INDICATORS,
	UPPER_INDICATORS,
	LOWER_INDICATORS,
	UPPER,
	LOWER,
} from "../Constants/IndicatorsConstants";
import "../Events/Events.scss";
import ErrorPopover from "../Popover/ErrorPopover";
import {
	removeIndicator,
	hasSomeParentTheClass,
	addSelectedDropDownColorStyle,
	removeSelectedDropDownColorStyle,
	addIndicatorToPanel,
} from "./IndicatorUtility";

const Indicators = (props) => {
	let newPanel = props.newPanel;
	const [indicators] = useState(INDICATORS);
	const [arrow, setArrow] = useState("fa-angle-down");
	const [show, setShow] = useState(false);
	const [showPopover, setShowPopover] = useState(false);
	const [target, setTarget] = useState(null);
	const upperIndicatorRef = useRef(null);
	const lowerIndicatorRef = useRef(null);
	const { t } = useTranslation();
	useEffect(() => {
		if (props.removeLegend) {
			let chartIndicators, indicator, panel, isMultiAdded;
			if (
				props.chart.panels.length > 1 &&
				props.chart.panels[1].indicators[0].params.id === props.removeLegend
			) {
				chartIndicators = props.chart.panels[1].indicators;
				indicator = chartIndicators.find((i) => i.params.id === props.removeLegend);
				panel = LOWER;
			} else {
				chartIndicators = props.chart.panels[0].indicators;
				indicator = chartIndicators.find(
					(i) => i.params.uid === props.removeLegend && i.params.id !== "price"
				);
				if (indicator) {
					isMultiAdded =
						chartIndicators.filter((x) => x.params.id === indicator.params.id).length ===
						1
							? false
							: true;
				}

				panel = UPPER;
			}
			if (indicator && !isMultiAdded) {
				removeSelectedDropDownColorStyle(
					indicator.params.id,
					upperIndicatorRef,
					lowerIndicatorRef
				);
			}

			props.checkStylesRemove(indicator);
			if (indicator) {
				removeIndicator(props, indicator, panel);
				props.chart.render();
				props.setRemoveLegend(null);
			}
		}
	}, [props.removeLegend]);

	function isAlreadyAdded(id) {
		let flag = false;
		props.chart.panels.forEach((panel) => {
			panel.indicators.forEach((ind) => {
				if (ind.params.id === id) flag = true;
			});
		});
		return flag;
	}

	function handleIndicator(e, id, panel) {
		if (isAlreadyAdded(id)) return;
		props.registerChartEvents();
		let indicator = indicators.find((i) => i.id === id);
		if (props.chart && panel === UPPER) {
			if (props.chart.panels[0].indicators.length < 11) {
				addIndicatorToPanel(props, indicator, panel, newPanel);
				addSelectedDropDownColorStyle(
					id,
					UPPER,
					upperIndicatorRef,
					lowerIndicatorRef,
					styles
				);
				props.checkStylesAdd();
			} else {
				setShowPopover(true);
				let parent = hasSomeParentTheClass(e.target);
				setTarget(parent);
			}
		} else if (props.chart && panel === LOWER) {
			addIndicatorToPanel(props, indicator, panel, newPanel);
			addSelectedDropDownColorStyle(
				id,
				LOWER,
				upperIndicatorRef,
				lowerIndicatorRef,
				styles
			);
		}
		return () => props.unregisterChartEvents();
	}

	const dropdownToggle = (isOpen) => {
		if (!isOpen) setShow(true);
		else setShow(false);
		if (arrow === "fa-angle-up") {
			setArrow("fa-angle-down");
			setShow(false);
		} else setArrow("fa-angle-up");
	};

	return (
		<>
			<div className="indicators-button" data-testid="indicatorsDropdown">
				<Dropdown show={show} onToggle={() => dropdownToggle(show)}>
					<Dropdown.Toggle
						className={styles.dropdownToggle}
						data-testid="dropdownButton"
						id="dropdownButton"
					>
						{t("Indicators")} <i className={`fa ${arrow} ${styles.DropDownIcon}`}></i>{" "}
					</Dropdown.Toggle>
					<Dropdown.Menu
						id="indicatorDropdown"
						className={`${styles.dropdownMenu} indicatorMenu`}
					>
						<Dropdown.ItemText className={styles.dropdownItemText}>
							{t("Upper Charts")}
						</Dropdown.ItemText>
						<div ref={upperIndicatorRef}>
							{UPPER_INDICATORS.map((indicator) => {
								return (
									<Dropdown.Item
										key={indicator.id}
										data-testid="itemIndicator"
										className={styles.dropdownItem}
										data-id={indicator.id}
										onClick={(e) => handleIndicator(e, indicator.id, UPPER)}
									>
										<div className="dropdown_item">
											<span className="dropdown_text" id="indicator-item">
												{t(indicator.label)}
											</span>
										</div>
									</Dropdown.Item>
								);
							})}
						</div>
						<Dropdown.ItemText className={`${styles.dropdownItemText} border-top`}>
							{t("Lower Charts")}
						</Dropdown.ItemText>
						<div ref={lowerIndicatorRef}>
							{LOWER_INDICATORS.map((indicator) => {
								return (
									<Dropdown.Item
										key={indicator.id}
										data-id={indicator.id}
										data-testid="itemIndicatorLower"
										className={styles.dropdownItem}
										onClick={(e) => handleIndicator(e, indicator.id, LOWER)}
									>
										<div className="dropdown_item">
											<span className="dropdown_text" id="indicator-item">
												{t(indicator.label)}
											</span>
										</div>
									</Dropdown.Item>
								);
							})}
						</div>
					</Dropdown.Menu>
				</Dropdown>
				<ErrorPopover
					showPopover={showPopover}
					target={target}
					setShowPopover={setShowPopover}
				></ErrorPopover>
			</div>
		</>
	);
};

export default Indicators;
