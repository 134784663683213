/* eslint-disable no-unused-expressions */
import React from "react";
import { useTranslation } from "react-i18next";
import CaretIcon from "../../assets/img/Caret_icon.svg";

export default function SubscriptionLink(props) {
	const { t } = useTranslation();

	return (
		<>
			<span
				onClick={() => {
					props.handleShow();
				}}
				data-testid="subscribe-link"
				id={props.className}
			>
				{t("Subscribe for more data from S&P Global")}
			</span>
			<img
				onClick={() => {
					props.handleShow();
				}}
				src={`${props.cdnDomain}${CaretIcon}`}
				alt="Right"
				data-testid="caret-link"
				id={props.className}
				className="subscribe-caret-icon"
			/>
		</>
	);
}
