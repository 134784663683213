import React, { useState, useEffect } from "react";
import "./TimeFrame.scss";
import TimeFrameConstant from "../Constants/TimeFrameConstants";
import styles from "components/Lib/Dropdown/DropdownStyles.module.scss";
import { Dropdown } from "react-bootstrap";
import moment from "moment";
import { useTranslation } from "react-i18next";

const TimeFrame = ({
	chart,
	chartRef,
	inceptiondate,
	unregisterChartEvents,
	registerChartEvents,
	selectedTimeframe,
}) => {
	const { t } = useTranslation();
	const [selectedTimeFrame, setSelectedTimeFrame] = useState(
		TimeFrameConstant.TIME_FRAMES[TimeFrameConstant.DEFAULT_TIME_FRAME_INDEX]
	);
	const [title, setTitle] = useState(selectedTimeFrame.label);
	const [arrow, setArrow] = useState("fa-angle-down");
	const [show, setShow] = useState(false);

	const handleTimeFrameOnSelect = (value) => {
		registerChartEvents();
		let getSelectedTimeFrame = TimeFrameConstant.TIME_FRAMES.filter(
			(timeFrame) => timeFrame.label === value
		)[0];

		setSelectedTimeFrame(getSelectedTimeFrame);
		selectedTimeframe && selectedTimeframe(getSelectedTimeFrame.numDays);
		// days count check for HKEX-252 point 8
		if (chart.params) {
			if (value !== "1 Day") {
				chart.params.prependNormalizedPoint = true;
				chart.params.returnPreviousPoint = true;
			} else {
				chart.params.prependNormalizedPoint = false;
				chart.params.returnPreviousPoint = false;
			}
		}
		return () => unregisterChartEvents();
	};

	useEffect(() => {
		if (chart && chartRef.current) {
			setTitle(selectedTimeFrame.label);
			if (selectedTimeFrame.keyLabel === "MAX") {
				var current = new moment();
				let days = Math.round(moment.duration(current.diff(inceptiondate)).asDays());
				chart.setDays(days);
			} else if (selectedTimeFrame.keyLabel === "YTD") {
				var currentDate = new moment();
				var year = currentDate.format("YYYY");
				let days = Math.round(
					moment.duration(currentDate.diff(`${year}-01-01`)).asDays()
				);
				chart.setDays(days);
			} else {
				chart.setDays(selectedTimeFrame.numDays);
			}
			chart.setDataInterval(selectedTimeFrame.defaultPeriod.interval);
			chart.setDataPeriod(selectedTimeFrame.defaultPeriod.value);
			chart.loadData();
		}
	}, [selectedTimeFrame]);

	const dropdownToggle = (isOpen) => {
		if (!isOpen) {
			setTitle("Range");
			setShow(true);
		} else {
			setTitle(selectedTimeFrame.label);
			setShow(false);
		}
		if (arrow === "fa-angle-up") {
			setArrow("fa-angle-down");
			setShow(false);
		} else setArrow("fa-angle-up");
	};

	return (
		<>
			<div className="time-frame" data-testid="timeframeDropdown">
				<Dropdown show={show} onToggle={() => dropdownToggle(show)}>
					<Dropdown.Toggle
						className={styles.dropdownToggle}
						data-testid="dropdownButton"
						id="dropdownButton"
					>
						<span data-testid="dropdown-title">{t(title)}</span>
						<i className={`fa ${arrow} ${styles.DropDownIcon}`}></i>{" "}
					</Dropdown.Toggle>
					<Dropdown.Menu className={`${styles.dropdownMenu} dropdownMenu`}>
						{TimeFrameConstant.TIME_FRAMES.map((timeframe) => {
							return (
								<Dropdown.Item
									key={timeframe.key}
									id="dropdown-item"
									data-testid="dropdown-item"
									data-value={timeframe.label}
									className={
										(selectedTimeFrame.keyLabel === timeframe.keyLabel
											? styles.selected
											: styles.dropdownItem) + " day-range"
									}
									onClick={() => handleTimeFrameOnSelect(timeframe.label)}
								>
									{t(timeframe.label)}
								</Dropdown.Item>
							);
						})}
					</Dropdown.Menu>
				</Dropdown>
			</div>
		</>
	);
};

export default TimeFrame;
